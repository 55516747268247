<template>
  <div class="music-player">
    <h2>音乐搜索器</h2>
    <p>多站合一音乐搜索解决方案</p>

    <form v-if="isFirstAreaVisible" style="display: block;">
      <!--<input v-model="songId" placeholder="例如：奇迹再现" />-->
      <el-input class="inputs" v-model="currentSongName" placeholder="例如：奇迹再现"></el-input>
      <el-alert v-if="alers" class="alerse" title="错误提示的文案" type="error" :closable="false">
      </el-alert>
      <div class="radios">
        <el-radio v-model="type" label="nest">网易音乐</el-radio>
        <el-radio v-model="type" label="qq">QQ音乐</el-radio>
        <el-radio v-model="type" label="ku">酷狗音乐</el-radio>
      </div>
      <br>
      <div>
        <el-button class="but" type="primary" :loading="isLoading" @click="getSong">Get √ </el-button>
      </div>
    </form>
    <!--提交后-->
    <div v-else class="music">
      <div>
        <button class="but2" @click="getfh">Get √ 返回继续</button>
      </div>
      <br>
      <!--音乐信息-->
      <div class="music-info">
        <div class="music-div2">
        </div>
        <div class="music-div">
          <el-button type="success" class="music-butt" @click="downloadFile" round>下载歌曲</el-button>
          <a :href="songUrl" ref="downloadLink" style="display: none;"></a>
        </div>
        <br>
        <el-row>
  <el-col :span="12"><div class="grid-content bg-purple"><el-input class="music-div2-input" v-model="songName" :disabled="true"></el-input></div></el-col>
  <el-col :span="12"><div class="grid-content bg-purple-light"><el-input class="music-div3-input" v-model="artist" :disabled="true"></el-input></div></el-col>
</el-row>
       <!-- <div class="music-div2">
          <el-input class="music-div2-input" v-model="songName" :disabled="true"></el-input>
        </div>
        <div class="music-div3">
          <el-input class="music-div3-input" v-model="artist" :disabled="true"></el-input>
        </div>-->
      </div>
      <br><br>

      <div id="aplayer" ref="aplayer"></div>
      <!-- <div>
        <h3>{{ songName }}</h3>
        <ul class="list" v-if="relatedSongs.length">
          <li v-for="song in relatedSongs" :key="song.n" @click="addmusic(song.song_title,song.n)">{{song.song_title}}-{{ song.song_singer }}</li>
        </ul>
      </div>-->


    </div>
    <!--列表-->
    <!--<div class="card-container">
      <ul class="scroll-list">
        <li v-for="song in relatedSongs" :key="song.id" class="list-item" @click="selectItem(song.id)"
          :class="{ 'selected': selectedIndex === song.id }">
          <div class="item-marker" v-if="selectedIndex === song.id"></div>
          <span class="item-text"> {{ song.name }}-{{ song.artist }}
          </span>
        </li>
      </ul>
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
import music from '@/store/music';
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
export default {
  name: 'HomeView',
  data() {
    return {
      isLoading: false,
      type: 'nest',
      songId: '',   // 音乐id
      songName: '', // 音乐名称
      songUrl: '',  // 音乐地址
      artist: '',//歌手
      cover: '',//封面
      lrc: '',
      num: '',
      link: '',
      currentSong: null,
      selectedIndex: 1,
      aplayer: null, // 播放器
      relatedSongs: [], //音乐列表
      alers: false,
      currentSongName: '',
      isFirstAreaVisible: true,
      isFirstAreaVisible2: '',
    }
  }, mounted() {
  },
  methods: {
    //提交表单
    async getSong() {
      this.isLoading = true;
      try {
        if (this.type == 'qq') {

          music.music_QQ(this.currentSongName, this.num).then(res => {
            if (res.data.code != '200') {
              this.isLoading = false;
              this.$message.error('ㄟ( ▔, ▔ )ㄏ 没有找到相关信息');
              return;
            }
            this.isLoading = false;
            this.relatedSongs = res.data.data;
            this.link = res.data.data[0].link;
            this.artist = res.data.data[0].artist;
            this.songName = res.data.data[0].name;
            this.cover = res.data.data[0].pic;
            this.songUrl = res.data.data[0].url;
            this.lrc = res.data.data[0].lrc;
            this.isFirstAreaVisible = false;
            this.$nextTick(() => {
              this.initAPlayer();
            });

            // this.initAPlayer();
            // this.loading = false;

          })
        }
        if (this.type == 'nest') {
          music.music_nest(this.currentSongName, this.num).then(res => {
            if (res.data.code != '200') {
              this.isLoading = false;
              this.$message.error('ㄟ( ▔, ▔ )ㄏ 没有找到相关信息');
              return;
            }
            this.isLoading = false;
            this.relatedSongs = res.data.data;
            this.link = res.data.data[0].links;
            this.artist = res.data.data[0].artist;
            this.songName = res.data.data[0].name;
            this.cover = res.data.data[0].pic;
            this.songUrl = res.data.data[0].url;
            this.lrc = res.data.data[0].lrc;
            this.isFirstAreaVisible = false;
            this.$nextTick(() => {
              this.initAPlayer();
            });
          })
        }
        if (this.type == 'ku') {
          music.music_ku(this.currentSongName, this.num).then(res => {
            if (res.data.code != '200') {
              this.isLoading = false;
              this.$message.error('ㄟ( ▔, ▔ )ㄏ 没有找到相关信息');
              return;
            }
            this.isLoading = false;
            this.relatedSongs = res.data.data;
            this.link = res.data.data[0].links;
            this.artist = res.data.data[0].artist;
            this.songName = res.data.data[0].name;
            this.cover = res.data.data[0].pic;
            this.songUrl = res.data.data[0].url;
            this.lrc = res.data.data[0].lrc;
            this.isFirstAreaVisible = false;
            this.$nextTick(() => {
              this.initAPlayer();
            });
          })
        }

      } catch (error) {
        if (error.response) {
          this.issLoading = false;
          this.alers=true;
          this.$mesage.error('ㄟ( ▔, ▔ )ㄏ 没有找到相关信息');

        } else {
          this.issLoading = false;
          this.alers=true;
          this.$mesage.error('ㄟ( ▔, ▔ )ㄏ 没有找到相关信息');
        }

      }
    },
    //返回表单
    getfh() {
      this.isFirstAreaVisible = true;
      if (this.aplayer && this.aplayer.pause) {
        this.aplayer.pause();
      }
      // 将实例设置为 null（不会触发清理，但断开了引用）  
      this.aplayer = null;
      /* this.$nextTick(() => {
         this.aplayer.destroy();
         });*/

    },
    /* addmusic(song_title, n) {
       if (this.type == 'qq') {
         try {
           music.music_QQ(song_title, n).then(ress => {
             if (ress.data.code != '200') {
               return;
             }
             this.songName = ress.data.data.song_name;
             this.songUrl = ress.data.data.music_url;
             this.cover = ress.data.data.cover;
             this.artist = ress.data.data.song_singer;
             this.lrc = ress.data.data.lyric;
             this.link = ress.data.data.links;
             console.log(this.relatedSongs);
           })
         } catch (error) {
           return;
         } finally {
           this.loading = false;
         }
       }
     },*/
    initAPlayer() {
      if (!this.aplayer) {
        // 播放器配置
        this.aplayer = new APlayer({
          container: document.getElementById('aplayer'),
          listFolded: false,  // Show playlist (not folded)
          listMaxHeight: '300px',  // Max height of playlist
          mini: false,
          autoplay: false,
          theme: '#FADFA3',
          loop: 'all',
          order: 'list',
          preload: 'auto',
          volume: 0.7,
          mutex: true,
          lrcType: 1,
          audio: this.relatedSongs,
          /*
          [
            {
              name: this.songName,
              artist: this.artist,
              url: this.songUrl,
              cover: this.cover,
              lrc: this.lrc,
            },
          ],*/
        });
        this.aplayer.play();
        // 监听列表切换事件
        this.aplayer.on('play', (obj) => {
          // 获取当前播放的歌曲信息
          const index = obj.index;
          console.log(index);
          this.isFirstAreaVisible2 = this.relatedSongs[this.aplayer.list.index];
          this.songName = this.isFirstAreaVisible2.name;
          this.songUrl = this.isFirstAreaVisible2.url;
          this.cover = this.isFirstAreaVisible2.pic;
          this.artist = this.isFirstAreaVisible2.artist;
          this.lrc = this.isFirstAreaVisible2.lrc;
          this.link = this.isFirstAreaVisible2.links;

        });
      }
      this.aplayer.play();
      this.aplayer.on('play', (obj) => {
          // 获取当前播放的歌曲信息
          const index = obj.index;
          console.log(index);
          this.isFirstAreaVisible2 = this.relatedSongs[this.aplayer.list.index];
          this.songName = this.isFirstAreaVisible2.name;
          this.songUrl = this.isFirstAreaVisible2.url;
          this.cover = this.isFirstAreaVisible2.pic;
          this.artist = this.isFirstAreaVisible2.artist;
          this.lrc = this.isFirstAreaVisible2.lrc;
          this.link = this.isFirstAreaVisible2.links;

        });

    },
    /* selectItem(index) {
       this.selectedIndex = index;
       var num = index - 1;
       this.isFirstAreaVisible2 = this.relatedSongs[num];
       this.songName = this.isFirstAreaVisible2.name;
       this.songUrl = this.isFirstAreaVisible2.url;
       this.cover = this.isFirstAreaVisible2.pic;
       this.artist = this.isFirstAreaVisible2.artist;
       this.lrc = this.isFirstAreaVisible2.lrc;
       this.link = this.isFirstAreaVisible2.links;
     },*/
    /*  addToPlaylist(song) {
        // Add song to the APlayer playlist
        this.aplayer.list.add({
          name: song.name,
          url: song.url,
          artist: song.artist,
          cover: 'https://linktoalbumcover.jpg', // Replace with actual cover image URL
          lrc: this.lrc,
        });
      }, */
    downloadFile() {
      this.$refs.downloadLink.click();
    },

  },
  beforeDestroy() {
    if (this.aplayer && this.aplayer.pause) {
      this.aplayer.pause();
    }
    this.aplayer = null;
    this.aplayer.destroy();
  }

}


</script>

<style scoped>
.music-player {
 /* display: inline;*/
  width: 100%;
  text-align: center;
 /* padding: 20px;*/
  font-family: Arial, sans-serif;
  margin-top: 5%;
}

input {
  width: 100%;
  display: block;
  margin: 10px 0;
  padding: 5px;
  font-size: 16px;
}

.but {
  font-size: 16px;
  width: 600px;
  height: 50px;
  color: aliceblue;
  background-color: #0e90d2;
  border-color: #0e90d2;
  text-align: center;
  margin-bottom: 0;
  border: 1px solid transparent;
  margin-left: 10px;
}

.but2 {
  font-size: 16px;
  width: 600px;
  height: 50px;
  color: aliceblue;
  background-color: #5eb95e;
  border-color: #5eb95e;
  text-align: center;
  margin-bottom: 0;
  border: 1px solid transparent;
  margin-left: 10px;
}



#aplayer {
  margin: auto;
  padding: 20px;
  width: 600px;
  text-align: left;
  font-size: 20px;
}

.scroll-list-container {
  width: 580px;
  height: 300px;
  /* 设置容器高度以产生滚动条 */
  overflow-y: auto;
  /* 允许垂直滚动 */
  border: 1px solid #ccc;
  /* 添加边框以区分容器 */
  padding: 20px;
  /* 添加内边距 */
  box-shadow: 0 10px 10px -10px white inset;
  margin: auto;
}

ul {
  list-style-type: none;
  /* 移除默认的列表样式 */
  padding: 0;
  /* 移除内边距 */
  margin: auto;
  /* 移除外边距 */
  text-align: left;
}

.scroll-list-item {
  padding: 10px;
  /* 为列表项添加内边距 */
  border-bottom: 1px solid #eee;
  /* 添加列表项之间的分隔线 */

}

.scroll-list-item:last-child {
  border-bottom: none;
  /* 移除最后一个列表项的下边框 */
}

.card-container {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 300px;
  padding: 16px;
  background-color: #fff;
  width: 570px;
  margin: 0 auto;
}

.scroll-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  position: relative;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  /* 列表项之间的分隔线 */
}

.list-item:last-child {
  border-bottom: none;
  /* 移除最后一个列表项的下边框 */
}

.list-item.selected {
  background-color: #f0f8ff;
  /* 稍微改变背景色以突出显示 */
}

.item-marker {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 100%;
  background-color: blue;
}

.item-text {
  margin-left: 16px;
  /* 为标记留出空间 */
  display: inline-block;
}

.inputs {
  width: 600px;
  height: 50px;
  text-align: center;
}

.alese {
  width: 600px;
  margin: auto;
}

.music-div {
  width: 300px;
  margin-left: 270px;
}

.music-div2 {
  width: 250px;
}

.music-butt {
  margin-left: -300px;
}

.music-div3 {
  width: 250px;
  margin-left: 270px;
  margin-top: -40px;
}

.music-div3-input {
  width: 250px;
}
.music{
  display: block;
  width: 600px;
  margin: auto;
}
.music-info{
  margin-left: 10%;
}

/* 宽带最大768px  */
@media (max-width: 768px) {
  .music-player {
   /*display: inline;*/
    width: 100%;
    text-align: center;
    font-family: Arial, sans-serif;
    overflow: hidden;
    margin-top: 30%;
  }
  .music{
    display: block;
    width: 100%;
    margin: auto;
    overflow: hidden;;
  }
  .inputs {
    width: 100%;
    margin: auto;
    overflow: hidden;;
  }

  .alese {
    width: 100%;
    margin: auto;
    overflow: hidden;;
  }

  .but {
    font-size: 16px;
    width: 100%;
    height: 50px;
    color: aliceblue;
    background-color: #0e90d2;
    border-color: #0e90d2;
    text-align: center;
    margin-bottom: 0;
    border: 1px solid transparent;
    margin-left: 0px;
    overflow: hidden;;
  }

  .but2 {
    font-size: 16px;
    width: 80%;
    height: 50px;
    color: aliceblue;
    background-color: #5eb95e;
    border-color: #5eb95e;
    text-align: center;
    margin-bottom: 0;
    border: 1px solid transparent;
    margin-left: 0px;
    overflow: hidden;;
  }

  .radios {
    width: 100%;
    margin: auto;
    padding: 0px;
    overflow: hidden;;
  }

  .music-info {
    width: 100%;
    margin: auto;
    overflow: hidden;;
  }

  /*.music_info div {
   width: 0%;
    margin-left: 0px;
    overflow: hidden;;
  }*/

  .music-div {
    width: 100%;
    margin-right: 0%;
  }

  .music-div3 {
    width: 250px;
    margin-left: 13%;
    margin-top: -5%;
    overflow: hidden;;
  }
  .music-div2 {
  width: 250px;
  margin-left: 2.5%;
  overflow: hidden;
  }

  .music-div3-input {
    width: 100%;
    margin-top: 7%;
   /* margin-left: -10.8%;
    overflow: hidden;*/
    display: block;
  }

  .music-div2-input {
    width: 100%;
    margin-top: 7%;
    margin-left: 16%;
    overflow: hidden;
    display: block;
  }

  .music-butt {
    margin-right: 80%;
    overflow: hidden;;

  }

  #aplayer {
    margin: auto;
    padding: 20px;
    width: 70%;
    text-align: left;
    font-size: 20px;
    overflow: hidden;;
  }
}</style>