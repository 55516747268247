import cfg from '@/store/request';

export default{
    //QQ音乐
    music_QQ(msg,size){
        try{
            return cfg.get('/api/music/qq.php?msg='+msg+'&n='+size)
        }catch(error){
            return ;
        }
        
    },
    //网易音乐
    music_nest(msg,size){
        return cfg.get('/api/music/nest.php?msg='+msg+'&n='+size)
    },
    //酷狗音乐
    music_ku(msg,size){
        return cfg.get('/api/music/ku.php?msg='+msg+'&n='+size)
    }

}