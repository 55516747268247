import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import Aplayer from 'vue-aplayer'


import {
  Message, MessageBox, Notification,
  Loading,
  Button, ButtonGroup,
  Radio, RadioGroup, Checkbox, CheckboxGroup,
  Input, InputNumber, Form, FormItem, DatePicker, Switch,
  Select, Option,
  Row, Col,
  Table, TableColumn,
  Link, Tabs, TabPane, 
  Pagination, Dialog,
  Upload
} from 'element-ui';

Vue.config.productionTip = false

Vue.use(Loading.directive);

Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Link);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(InputNumber);
Vue.use(Upload);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

new Vue({
  Aplayer,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
